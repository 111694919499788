<template>
  <div>
    <Form :model="addChangeData" :label-colon="true" :inline="true">
      <FormItem style="width: 310px; margin-bottom: 0px">
         <span slot="label" class="validate">服务月份:</span>
        <DatePicker
          type="month"
          v-model="addChangeData.yearofmonth"
          placeholder="请选择服务年份"
          style="calc(100% - 50px)"
        ></DatePicker>
      </FormItem>
      <FormItem style="width: 25%; margin-bottom: 0px">
        <Checkbox v-model="addChangeData.repeatMonth">每月重复</Checkbox>
      </FormItem>
      <Button
      type="primary"
      icon="md-add"
      style="float:right"
      @click="addTableRow"
      >新建计划</Button
    >
    </Form>
    <Divider style="margin: 10px 0" />
    <LiefengTable
      :talbeColumns="addChangeData.tableColumns"
      :tableData="addChangeData.tableData"
      :hidePage="true"
       :fixTable="true"
      class="planTable"
    ></LiefengTable>

    <!-- 目标人员 -->
    <LiefengModal
      title="目标人员"
      :value="targetStatus"
      @input="targetStatusFn"
     :fullscreen="true"
    >
      <template v-slot:contentarea>
        <Target ref="target" v-if="targetStatus" :targetList ="addChangeData.targetList"></Target>
      </template>
      <template v-slot:toolsbar>
        <Button
          @click="targetStatusFn(false)"
          type="info"
          style="margin-right: 10px"
          >关闭</Button
        >
        <Button @click="targetSave" type="primary">保存</Button>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import Target from "@/views/archives/childrens/addChangePlanChildrens/target ";
export default {
  props: ["optionsList","planTableList","addChangeIden"],
  data() {
    return {
      addChangeData: {
        tableColumns: [
          {
            title: "计划名称",
            key: "planName",
            fixed: "left",  
            minWidth: 200,
            align: "center",
            render: (h, params) => {
              return h("Input", {
                props: {
                  value: params.row.planName,
                },
                on: {
                  "on-change": (e) => {
                    this.addChangeData.tableData[params.index].planName =
                      e.target.value;
                  },
                },
              });
            },
          },
          {
            title: "服务内容",
            align: "center",
            children: this.optionsList ? this.optionsList.map((item,index) => {
              return {
                title: item.name,
                 align: "center",
                 width: 200,
                 render: (h, params) => {
                  return h(
                    "Select",
                    {
                      props: {
                        value: this.addChangeData.tableData[params.index].servicePlanProjectVos ? this.addChangeData.tableData[params.index].servicePlanProjectVos[index].frequency : 0,
                        transfer: true,
                      },
                      style: {
                        textAlign: "left",
                      },
                      on: {
                        "on-change": (val) => {
                          this.addChangeData.tableData[params.index].servicePlanProjectVos[index].frequency = val;
                        },
                      },
                    },
                    [
                      this.frequencyList.map((item) => {
                        return h(
                          "Option", 
                          {
                            props: {
                              value: item.value,
                            },
                          },
                          item.label
                        );
                      }),
                    ]
                  );
                },
              }
            }) : [],
          },
          {
            title: "操作",
            width: 170,
            fixed: "right",
            align: "center",
            render: (h, params) => {
              return h('div',[
                h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px"
                  },
                  on: {
                    click: () => {
                      this.addChangeData.infoIndex = params.index;
                      this.addChangeData.targetList = this.addChangeData.tableData[this.addChangeData.infoIndex].archives;
                      this.targetStatus = true;
                    },
                  },
                },
                "目标人员"
              ),
                h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      if(this.addChangeData.tableData.length == 1) {
                        this.$Message.error({
                          background: true,
                          content: "删除失败：请至少设置一项计划"
                        })
                        return;
                      }
                      this.addChangeData.tableData.splice(params.index,1)
                    },
                  },
                },
                "删除"
              ),
              ])
            },
          },
        ],
        tableData: [],
        //目标人员
        targetList: [],  //回显的目标人员
        infoIndex: '',
        archiveIdArr: [],    //保存的每个计划的目标人员
      },
      frequencyList: [
        //服务频率
        {
          value: 0,
          label: "无",
        },
        {
          value: 1,
          label: "每周一次",
        },
        {
          value: 2,
          label: "每周两次",
        },
        {
          value: 3,
          label: "每周三次",
        },
        {
          value: 4,
          label: "每周四次",
        },
      ],
      //目标人员
      targetStatus: false,
      oldYearofmonth: '',  //修改前的所属月份
    };
  },
  methods: {
    save() {
      if(!this.addChangeData.yearofmonth) {
        this.$Message.error({
          background: true,
          content: "请选择服务月份"
        });
        return;
      }
      let yearofmonth = this.$core.formatDate(new Date(this.addChangeData.yearofmonth),"yyyyMM");
      let params = this.addChangeData.tableData.map(item => {
        return {
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
          planName: item.planName,
          yearofmonth: yearofmonth,
          repeatMonth: this.addChangeData.repeatMonth ? '1' : '2',
          staffName: parent.vue.loginInfo.userinfo.realName,
          oemCode: parent.vue.oemInfo.oemCode,
          addServicePlanProjectRos: item.servicePlanProjectVos,
          archiveIds: item.archiveIdArr ? (() => {
            let arr = [];
            item.archiveIdArr.map(subItem => {
              arr.push(subItem.archiveId);
            })
            return arr;
          })(): [],
        }
      })
      let url = '';
      if(this.addChangeIden) {
        url = "/archives/api/pc/servicePlan/add";
      }else {
        url = "/archives/api/pc/servicePlan/update"
        params = {
          ros: params,
          oldYearofmonth: this.oldYearofmonth
        }
      }
      this.$post(url,
       params
      ,{"Content-Type": "application/json"}).then(res => {
        if(res.code == 200) {
          this.$Message.success({
            background: true,
            content: "保存成功"
          })
          this.$emit("successSave")
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      }).catch(err => {
        console.log(err);
        this.$Message.error({
          background: true,
          content: "保存失败，请联系管理员处理"
        })
      })
    },
    // 新建计划
    addTableRow() {
      this.addChangeData.tableData.push({
        planName: '',
        servicePlanProjectVos: this.optionsList.map(item => {
          return {
            projectId: item.projectId,
            frequency: 0,
          }
        })
      })
    },
    targetStatusFn(status) {
      this.targetStatus = status;
    },
    targetSave() {
      this.addChangeData.tableData[this.addChangeData.infoIndex].archiveIdArr = this.$refs.target.save();
      this.addChangeData.tableData[this.addChangeData.infoIndex].archives = this.addChangeData.tableData[this.addChangeData.infoIndex].archiveIdArr;
      this.targetStatus = false;
    }
  },
  created() {
    if(this.planTableList && this.planTableList.length > 0) {  //此时为修改或者复制
      this.addChangeData.tableData = this.planTableList.map(item => {
        return {
          ...item,
          archiveIdArr: item.archives
        }
      })
      this.addChangeData.yearofmonth = this.planTableList[0].yearofmonth;
      this.oldYearofmonth = this.addChangeData.yearofmonth;
      this.addChangeData.repeatMonth = this.planTableList[0].repeatMonth == '1' ? true : false;
    }else {
      this.addTableRow();
    }
    
  },
  components: {
    LiefengTable,
    LiefengModal,
    Target,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.planTable {
  /deep/.ivu-table-body,/deep/.ivu-table-tip {
    height: calc(100% - 80px);
  }
}
</style>